import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Terms from '@/views/category/TermsOfService.vue';
import Layout from '../views/MainLayout.vue';
/* import HomeMobile from '../views/mobile/Home_mobile.vue';
import HomePc from '../views/pc/Home_pc.vue'; */
import Home from '../views/HomeView.vue';
import Con from '../views/category/ContactUs.vue';
import PrivacyPolicy from '../views/category/PrivacyPolicy.vue';
import IOSPrivacyPolicy from '../views/category/IOSPrivacyPolicy.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/',
        component: Home, // 动态选择组件
        meta: { transition: '' },
      },
      {
        path: '/terms',
        component: Terms,
        meta: { transition: '' },
      },
      {
        path: '/con',
        component: Con,
      },
      {
        path: '/privacy',
        component: PrivacyPolicy,
        meta: { transition: '' },
      },
      {
        path: '/iosprivacy',
        component: IOSPrivacyPolicy,
        meta: { transition: '' },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
