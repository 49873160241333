<template>
  <!-- <AppTopNav /> -->
  <div class="container">
    <div class="app-header-nav" id="part_1">
      <img src="@/assets/images/star.jpg" alt="">
      <div class="tab">
        <a href="#part_1" class="tablinks_1">part_1</a><br>
        <a href="#part_2" class="tablinks_2">part_2</a><br>
        <a href="#part_3" class="tablinks_3">part_3</a><br>
        <a href="#part_4" class="tablinks_4">part_4</a>
      </div>
      <div class="content_1" id="part_2">
        <span class="wrapper text_1">FREEGUARD VPN</span>
        <span class="wrapper text_2">Unlimited Fast & Secure Proxy</span>
        <span class="wrapper text_3">
          Protect your online privacy with fast and secure free VPN proxy</span>
        <div class="download">
          <img src="@/assets/images/download_image2.png" alt="">
          <a href="https://play.google.com/store/apps/details?id=com.freeguardvpn.app"
            class="wrapper text_4">Downloads</a>
        </div>
      </div>
    </div>
    <div class="background_1">
      <div class="part_start">
        <img src="@/assets/images/image1.png" alt="" class="image_1">
        <div class="content_2">
          <span class="wrapper text_6" id="part_3">FEATURES INTRO</span>
          <span class="wrapper text_7">Protect privacy</span>
          <span class="wrapper text_8">
            Help you protect your online privacy,
            especially when connecting to public Wi-Fi networks.</span>
          <span class="wrapper text_9">Bypass geographic restrictions</span>
          <span class="wrapper text_10">Allow you to bypass geographic restrictions and
            access content and websites that may be blocked in your respective regions.</span>
          <span class="wrapper text_11">Improve network speed</span>
          <span class="wrapper text_12">Some of you may need improve network speeds,
            especially when your internet connections are slow.
            That’s exactly what FreeGuard VPN is capable of.</span>
        </div>
      </div>
      <div class="part_start">
        <img src="@/assets/images/image2.png" alt="" class="image_2">
        <div class="content_3">
          <span class="wrapper text_13">Improve online security</span>
          <span class="wrapper text_14">Help you protect your online privacy,
            especially when connecting to public Wi-Fi networks.</span>
          <span class="wrapper text_15">Download and share files</span>
          <span class="wrapper text_16">Can allow you to download and share
            files anonymously,
            especially when using P2P sharing.</span>
          <span class="wrapper text_17">Access international services</span>
          <span class="wrapper text_18">
            Can enable you to access international streaming services and websites,
            such as Netflix and Hulu.</span>
          <span class="wrapper text_19" id="part_2">Bypass website censorship</span>
          <span class="wrapper text_20" id="part_3">Can help you from countries
            bypass website censorship.</span>
        </div>
      </div>
      <div class="part_end">
        <span class="text_21" id="part_4">USER COMMENTS</span>
        <div class="background_2">
          <div class="image3">
            <div class="circle">
              <div class="circle_1">
                <h3>C</h3>
              </div>
            </div>
            <div class="rectangle">
              <p>Chloe ***</p>
              <div class="text_22">This app is pretty useful and stable.
                I would like to recommend it to my family and friends.
                Please keep going to improve it!</div>
              <img src="@/assets/images/star_1.png" alt="">
            </div>
          </div>
          <div class="image4">
            <div class="circle">
              <div class="circle_2">
                <h3>M</h3>
              </div>
            </div>
            <div class="rectangle">
              <p>Mohammad ***</p>
              <div class="text_22">Best apps. Very easy to connect.</div>
              <img src="@/assets/images/star_1.png" alt="">
            </div>
          </div>
          <div class="image5">
            <div class="circle">
              <div class="circle_3">
                <h3>G</h3>
              </div>
            </div>
            <div class="rectangle">
              <p>Gregory ***</p>
              <div class="text_22">
                It works I can for sure tell you that much it does get the job done</div>
              <img src="@/assets/images/star_1.png" alt="">
            </div>
          </div>
          <img src="@/assets/images/bg2.png" alt="">
        </div>
        <div class="app-end-nav">
          <a href="mailto:support@planetlinkinc.com">
            If you want to cooperate with us, you can contact us by email</a><br>
          <a href="mailto:support@planetlinkinc.com">support@planetlinkinc.com</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/style/base_pc.css';
// import '@/assets/style/markdown_pc.less';

export default {
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY <= 0) {
        window.scrollTo(0, 0); // 添加分号
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  max-width: 1400px;
  margin: 0 auto;
}

img {
  width: 100%;
  height: 90%;
  object-fit: cover;
}

.app-header-nav {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.tab {
  position: absolute;
  float: left;
  margin: 155px 0 0 70px;
  z-index: 2;
}

.tab a {
  outline: none;
  text-decoration: none;
  display: inline-block; // 设置成为行内块元素
  color: rgba(255, 255, 255, 0);
}

.tab a:hover {
  background-color: #6771FF;
}

.tablinks_1 {
  border-radius: 50px 50px 0 0;
  background-color: #6771FF;
  width: 3px;
  height: 35px;
}

.tablinks_2 {
  margin: 5px 0;
  background-color: rgb(34, 32, 74);
  width: 3px;
  height: 35px;
}

.tablinks_3 {
  margin: 0 0 5px 0;
  background-color: rgb(34, 32, 74);
  width: 3px;
  height: 35px;
}

.tablinks_4 {
  border-radius: 0 0 50px 50px;
  background-color: rgb(34, 32, 74);
  width: 3px;
  height: 35px;
}

.content_1 {
  position: absolute;
  height: 500px;
  margin: 160px 0 0 143px;
  z-index: 1;
}

.wrapper {
  // 宽度自适应文字
  text-align: left;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  /* 换行 */
  justify-content: flex-start;
  /* 左对齐*/
}

.text_1 {
  font-size: 35px;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #237FFF, #75D5FF, #ED5AFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_2 {
  margin: 10px 0;
  font-size: 35px;
  font-weight: 600;
  color: #fff;
}

.text_3 {
  margin: 40px 0;
  font-size: 16px;
  color: #fff;
}

.download {
  position: relative;
  align-items: center;
  display: inline-block;
  margin: 30px 0;
}

.download img {
  width: 205px;
  height: 64px;
}

.text_4 {
  position: absolute;
  text-align: center;
  line-height: 38px;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: repeating-linear-gradient(to right, #237FFF, #75D5FF, #ED5AFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 450;
}

.app-next-nav {
  height: 600px;
  /*  position: absolute; */
  /* justify-content: space-between; */
}

.content_2 {
  position: absolute;
  float: left;
  top: -5%;
  left: 10%;
  width: 500px;
}

.image_1 {
  position: absolute;
  float: right;
  top: -5%;
  right: 5%;
  width: 600px;
  height: 600px;
}

.text_6 {
  font-size: 35px;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #FAD62C, #E664FF, #4FAEFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_7 {
  margin: 70px 0 0;
  font-size: 16px;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #6BA9FF, #BDFAFF, #F6AAFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_8 {
  margin: 10px 0 0;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}

.text_9 {
  margin: 40px 0 0;
  font-size: 22px;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #6BA9FF, #BDFAFF, #F6AAFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_10 {
  margin: 10px 0 0;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}

.text_11 {
  margin: 40px 0 0;
  font-size: 22px;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #6BA9FF, #BDFAFF, #F6AAFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_12 {
  margin: 10px 0 0;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}

.background_1 {
  position: relative;
  background-image: url('@/assets/images/bg.jpg');
  background-size: 100% 100%;
  /* 图片将被拉伸以填充整个容器 */
  background-position: center top;
  /* 控制背景图片的位置，与上方盒子无缝拼接 */
  width: 100%;
  height: 100%;
  /* 设置 div 的高度为整个视口高度 */
}

.part_start {
  height: 600px;
}

.part_end {
  height: 800px;
}

.image_2 {
  position: absolute;
  float: left;
  top: 25%;
  left: 3%;
  transform: translateX(10%);
  width: 600px;
  height: 600px;
}

.content_3 {
  position: absolute;
  width: 500px;
  top: 25%;
  left: 58%;
  transform: translateY(25%);
}

.text_13 {
  font-size: 22px;
  font-weight: 600;
  background: repeating-linear-gradient(to right, #6BA9FF, #BDFAFF, #F6AAFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_14 {
  margin: 10px 0 0;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}

.text_15 {
  font-size: 22px;
  font-weight: 600;
  margin: 40px 0 0;
  background: repeating-linear-gradient(to right, #6BA9FF, #BDFAFF, #F6AAFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_16 {
  margin: 10px 0 0;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}

.text_17 {
  font-size: 22px;
  font-weight: 600;
  margin: 40px 0 0;
  background: repeating-linear-gradient(to right, #6BA9FF, #BDFAFF, #F6AAFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_18 {
  margin: 10px 0 0;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}

.text_19 {
  font-size: 22px;
  font-weight: 600;
  margin: 40px 0 0;
  background: repeating-linear-gradient(to right, #6BA9FF, #BDFAFF, #F6AAFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_20 {
  margin: 10px 0 0;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}

.background_2 {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-size: cover;
  width: 100%;
  height: 600px;
}

.rectangle {
  position: relative;
  top: 20%;
  border-radius: 0 90px 30px 30px;
  width: 300px;
  height: 200px;
  background-color: #fff;
}

.rectangle img {
  position: absolute;
  top: 75%;
  left: 8%;
  width: 170px;
  height: 25px;
}

.circle {
  position: absolute;
  top: 5%;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  background-color: #fff;
  z-index: 1;
}

.circle_1 {
  position: absolute;
  top: 10%;
  left: 10%;
  border-radius: 50px;
  width: 80px;
  height: 80px;
  background-color: rgb(214, 138, 52);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.circle_2 {
  position: relative;
  position: absolute;
  top: 10%;
  left: 10%;
  border-radius: 50px;
  width: 80px;
  height: 80px;
  background-color: rgb(98, 198, 133);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.circle_3 {
  position: absolute;
  top: 10%;
  left: 10%;
  border-radius: 50px;
  width: 80px;
  height: 80px;
  background-color: rgb(226, 105, 137);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

h3 {
  margin: 0;
  font-size: 60px;
  color: #fff;
}

p {
  padding-top: 25px;
  padding-left: 110px;
  font-size: 17px;
  font-weight: bold;
}

.image3 {
  position: absolute;
  float: left;
  /* width: 350px; */
  height: 350px;
  margin: 150px 0 0 0;
  left: 10%;
  z-index: 1;
}

.image4 {
  position: absolute;
  /* width: 350px; */
  height: 350px;
  margin: 150px 0 0 0;
  left: 50%;
  transform: translateX(-50%);
}

.image5 {
  position: absolute;
  /* width: 350px; */
  height: 350px;
  margin: 150px 0 0 0;
  right: 10%;
}

.text_21 {
  position: absolute;
  margin: 70px 0 0 150px;
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  z-index: 2;
}

.text_22 {
  padding: 20px 25px 0 25px;
  font-size: 16px;
}

.app-end-nav {
  position: absolute;
  top: 1850px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
}

.app-end-nav a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7A72C7;
}
</style>
