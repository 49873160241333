<template>
  <div class="markdown-body">
    <VueShowdown :markdown="markdown" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import termsContent from '@/assets/text/terms';

const markdown = ref(termsContent);
</script>

<style>
</style>
