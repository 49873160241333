<template>
  <div class="app-top-nav">
    <div class="w">
      <ul>
        <li v-for="(item, index) in navItems" :key="index">
          <Router-Link :to="item.path" :class="{ active: currentRoute === item.path }">
            {{ item.name }}
          </Router-Link>
        </li>
        <li><a href="mailto:support@planetlinkinc.com?subject=Customer%20Service">Contact</a></li>
      </ul>
      <div class="download">
        <img src="@/assets/images/download_image.png" alt="" />
        <a href="https://play.google.com/store/apps/details?id=com.freeguardvpn.app" class="text_6">Downloads</a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const navItems = [
  { name: 'Home', path: '/' },
  { name: 'Terms of service', path: '/terms' },
  { name: 'Privacy policy', path: '/privacy' },
  { name: 'IOS Privacy Policy', path: '/iosprivacy' },
];

const currentRoute = computed(() => route.path);
</script>

<style lang="less" scoped>
.app-top-nav {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  background-color: rgb(24, 21, 48);
  /* margin: 0 auto; */
  z-index: 3;
  line-height: 80px;

  ul {
    display: flex;
    // margin: 0 5%;

    li {
      position: relative;
      left: -8%;
      width: auto;

      a:first-child {
        padding: 0 30px;
      }

      a {
        font-family: PingFang SC;
        padding: 0 30px;
        color: #d7d6e3;
        font-weight: 500;
        font-size: 16px;
        transition: color 0.3s;

        &:hover,
        &.active {
          background: repeating-linear-gradient(to right, #fad62c, #e664ff, #4faeff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}

.w {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
}

.download {
  position: relative;
  left: 2%;
  align-items: center;
  display: flex;
}

.download img {
  width: 125px;
  height: 38px;
}

.text_6 {
  position: absolute;
  text-align: center;
  line-height: 35px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-family: PingFang SC;
  // font-weight: 500;
  font-size: 16px;
}
</style>
