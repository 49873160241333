<template>
  <div>
    <component :is="currentHomeComponent" />
    <!--  <component :is="currentNavComponent" /> -->
  </div>
</template>

<script>
import {
  ref,
} from 'vue';
import HomePc from '@/views/pc/Home_pc.vue';
import HomeMobile from '@/views/mobile/Home_mobile.vue';
/* import AppTopNav from '@/components/AppTopNav.vue';
import AppTopNavMobile from '@/components/AppTopNavMobile.vue'; */

window.addEventListener('resize', () => {
  console.log(`Window resized to: ${window.innerWidth}x${window.innerHeight}`);
  console.log(window.innerWidth);
});

export default {
  setup() {
    const detectDevice = () => {
      const isMobile = window.innerWidth <= 748;
      return isMobile ? HomeMobile : HomePc;
    };

    // const detectNavComponent = () => {
    //   const isMobile = window.innerWidth <= 748;
    //   return isMobile ? AppTopNavMobile : AppTopNav;
    // };

    const currentHomeComponent = ref(detectDevice());
    // const currentNavComponent = ref(detectNavComponent());

    return {
      currentHomeComponent,
      // currentNavComponent,
    };
  },
};
</script>
