<template>
  <div class="app-top-nav">
    <div class="w">
      <ul>
        <li v-for="(item, index) in navItems" :key="index">
          <Router-Link :to="item.path" :class="{ active: currentRoute === item.path }">
            {{ item.name }}
          </Router-Link>
        </li>
        <li><a href="mailto:support@planetlinkinc.com?subject=Customer%20Service">Contact</a></li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const navItems = [
  { name: 'Home', path: '/' },
  { name: 'Terms of service', path: '/terms' },
  { name: 'Privacy policy', path: '/privacy' },
  { name: 'IOS Privacy Policy', path: '/iosprivacy' },
];

const currentRoute = computed(() => route.path);
</script>

<style lang="less" scoped>
.app-top-nav {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  display: flex;
  align-items: flex-end;
  overflow-x: auto; // 允许水平滚动
  white-space: nowrap; // 防止内容换行
  text-align: center;
  background-color: #150F25;
  z-index: 3;

  .w {
    width: 100%;
  }

  ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    white-space: nowrap; // 防止内容换行
  }

  li {
    display: inline-block; // 改为 inline-block 以防止换行
    flex-shrink: 0; // 防止 li 收缩
    text-align: center;

    a {
      display: block;
      font-family: PingFang SC;
      padding: 0 5vw 1vw 3vw;
      color: #d7d6e3;
      font-weight: 550;
      font-size: 3.5vw;
      transition: color 0.3s;
      // line-height: 10px;

      &:hover,
      &.active {
        background: repeating-linear-gradient(to right, #fad62c, #e664ff, #4faeff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
</style>
