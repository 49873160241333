import { createApp } from 'vue';
import { VueShowdown } from 'vue-showdown';
import App from './App.vue';
import router from './router';
import store from './store';
// // import 'amfe-flexible';
import '@/assets/style/markdown.less';
// import '@/assets/style/markdown_mobile.less';

console.log(`App version: ${process.env.VUE_APP_VERSION}`);
console.log('111');
createApp(App)
  .use(store)
  .use(router)
  .component('VueShowdown', VueShowdown as any)
  .mount('#app');
// app.component('VueShowdownPlugin', VueShowdownPlugin);

console.log('@@@test!!!');
