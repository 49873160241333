import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactUs',
  setup(__props) {

const hrefContent = ref('mailto:support@planetlinkinc.com?subject=Customer%20Service');


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, _toDisplayString(hrefContent.value), 1))
}
}

})