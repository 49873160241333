import { Commit } from 'vuex'; // 导入 Commit 类型

interface State {
  userinfo: {
    token: string;
    username: string;
  };
}

export default {
  namespaced: true,
  state: (): State => ({
    userinfo: {
      token: '123',
      username: 'Cristina',

    },
  }),
  mutations: {
    updateName(state: State, payload: string) { // 为 state 和 payload 指定类型
      state.userinfo.username = payload;
    },
  },
  actions: {
    updateNameAsync({ commit }: { commit: Commit }, payload: string) { // 为 commit 和 payload 指定类型
      setTimeout(() => {
        commit('updateName', payload);
      }, 2000);
    },
  },
  getters: {
    // 可以定义一些 getters
  },
};
