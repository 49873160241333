import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/images/download_image.png'


const _hoisted_1 = { class: "app-top-nav" }
const _hoisted_2 = { class: "w" }

import { computed } from 'vue';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppTopNav',
  setup(__props) {

const route = useRoute();

const navItems = [
  { name: 'Home', path: '/' },
  { name: 'Terms of service', path: '/terms' },
  { name: 'Privacy policy', path: '/privacy' },
  { name: 'IOS Privacy Policy', path: '/iosprivacy' },
];

const currentRoute = computed(() => route.path);

return (_ctx: any,_cache: any) => {
  const _component_Router_Link = _resolveComponent("Router-Link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", null, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(navItems, (item, index) => {
          return _createElementVNode("li", { key: index }, [
            _createVNode(_component_Router_Link, {
              to: item.path,
              class: _normalizeClass({ active: currentRoute.value === item.path })
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1032, ["to", "class"])
          ])
        }), 64)),
        _cache[0] || (_cache[0] = _createElementVNode("li", null, [
          _createElementVNode("a", { href: "mailto:support@planetlinkinc.com?subject=Customer%20Service" }, "Contact")
        ], -1))
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "download" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }),
        _createElementVNode("a", {
          href: "https://play.google.com/store/apps/details?id=com.freeguardvpn.app",
          class: "text_6"
        }, "Downloads")
      ], -1))
    ])
  ]))
}
}

})