import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "markdown-body" }

import { ref } from 'vue';
import termsContent from '@/assets/text/terms';


export default /*@__PURE__*/_defineComponent({
  __name: 'TermsOfService',
  setup(__props) {

const markdown = ref(termsContent);

return (_ctx: any,_cache: any) => {
  const _component_VueShowdown = _resolveComponent("VueShowdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VueShowdown, { markdown: markdown.value }, null, 8, ["markdown"])
  ]))
}
}

})