<template>
  <div id="app">
    <!-- transition组件包裹router-view -->
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script lang="ts" setup>

</script>

<style lang="less" scoped></style>
