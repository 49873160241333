export default `# IOS Privacy Policy | FreeGuard VPN

This privacy policy applies to the Freeguard VPN app (hereby referred to as "Application") for mobile devices that was created by planetlink INC (hereby referred to as "Service Provider") as a Free service. This service is intended for use "AS IS".

## Information Collection and Use

The Application does not obtain any information when you download and use it. Registration is not required to use the Application.

### Does the Application collect precise real time location information of the device?

This Application does not collect precise information about the location of your mobile device.

### Do third parties see and/or have access to information obtained by the Application?

Since the Application does not collect any information, no data is shared with third parties.

### What are my opt-out rights?

You can stop all collection of information by the Application easily by uninstalling it. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.

## Children’s Privacy

These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 years of age. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do the necessary actions.

## Security

The Service Provider is concerned about safeguarding the confidentiality of your information. However, since the Application does not collect any information, there is no risk of your data being accessed by unauthorized individuals.

## Changes

This Privacy Policy may be updated from time to time for any reason. The Service Provider will notify you of any changes to their Privacy Policy by updating this page with the new Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.

This privacy policy is effective as of 2024-08-22.

## Your Consent

By using the Application, you are consenting to the processing of your information as set forth in this Privacy Policy now and as amended by the Service Provider.

## Contact Us

If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at [support@planetlinkinc.com](mailto:support@planetlinkinc.com).`;
