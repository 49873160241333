<template>
  <!-- 顶部通栏 -->
  <component :is="currentNavComponent" />
  <!-- 主题区域 -->
  <div class="main">
    <!-- 二级路由 -->
    <router-view></router-view>
  </div>
</template>

<script>
import AppTopNavMobile from '@/components/AppTopNavMobile.vue';
import AppTopNavPC from '@/components/AppTopNav.vue';

export default {
  components: {
    AppTopNavMobile,
    AppTopNavPC,
  },
  computed: {
    currentNavComponent() {
      // 根据屏幕宽度选择组件
      console.log('@@@currentNavComponent: ', window.innerWidth);
      return window.innerWidth <= 748 ? 'AppTopNavMobile' : 'AppTopNavPC';
    },
  },
  mounted() {
    // 监听窗口大小变化
    window.addEventListener('resize', this.updateNavComponent);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateNavComponent);
  },
  methods: {
    updateNavComponent() {
      console.log('@@@resize');
      this.$forceUpdate(); // 强制重新渲染
    },
  },
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  overscroll-behavior: none;
}

.main {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
