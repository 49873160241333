<template>
  <div class="markdown-body">
    <VueShowdown :markdown="markdown" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import iprivacyContent from '@/assets/text/Iprivacy';

const markdown = ref(iprivacyContent);
</script>

<style></style>
