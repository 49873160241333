<template>
  <!-- <div>分类页面3</div> -->
  <!-- <a href="mailto:support@planetlinkinc.com?subject=Customer%20Service"> -->
  <div>{{ hrefContent }}</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const hrefContent = ref('mailto:support@planetlinkinc.com?subject=Customer%20Service');

</script>

<style>
</style>
