<template>
  <div class="markdown-body">
    <VueShowdown :markdown="markdown" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import privacyContent from '@/assets/text/privacy';

const markdown = ref(privacyContent);
</script>

<style>
.background {
  width: 100%;
  height: 100%;
  background-color: rgb(30, 22, 54);
}
</style>
